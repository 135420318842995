<template>
      <section class="sparklet-team-area sparklet-team-about-area sparklet-team-8-area pt-90 pb-90">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="sparklet-section-title text-center">
                        <h3 class="sparklet-title">Meet our Team Members</h3>
                        <p>Different layouts and styles for team sections.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="sparklet-team-item sparklet-team-item-about sparklet-team-item-8 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="thumb">
                            <img src="@/assets/images/team-1.jpg" alt="">
                            <ul>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div class="content text-left">
                            <h5 class="title">Benjamin Evalent</h5>
                            <span>CEO-Founder</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="sparklet-team-item sparklet-team-item-about sparklet-team-item-8 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="thumb">
                            <img src="@/assets/images/team-2.jpg" alt="">
                            <ul>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div class="content text-left">
                            <h5 class="title">Benjamin Evalent</h5>
                            <span>CEO-Founder</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="sparklet-team-item sparklet-team-item-about sparklet-team-item-8 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="thumb">
                            <img src="@/assets/images/team-3.jpg" alt="">
                            <ul>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div class="content text-left">
                            <h5 class="title">Benjamin Evalent</h5>
                            <span>CEO-Founder</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="sparklet-team-item sparklet-team-item-about sparklet-team-item-8 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                        <div class="thumb">
                            <img src="@/assets/images/team-4.jpg" alt="">
                            <ul>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <div class="content text-left">
                            <h5 class="title">Benjamin Evalent</h5>
                            <span>CEO-Founder</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="team-btn text-center mt-50">
                        <a class="main-btn" href="#"> View all Members <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>