<template>
  <!--====== sparklet FOOTER PART START ======-->

  <section class="sparklet-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <!-- <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img src="../../assets/images/logo.png" alt="" /></a>
            </div>
            <p>
              Sparklet WordPress is theme is the last theme you will ever have.
            </p>
            <a href="#">Read More <i class="fal fa-arrow-right"></i></a>
            <div class="social mt-30">
              <ul>
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-pinterest-p"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
          </div> -->
        </div>
        <div class="col-lg-2 col-md-6">
          <!-- <div class="footer-navigation">
            <h4 class="title">Company</h4>
            <ul>
              <li><router-link to="/about-us">About Us</router-link></li>
              <li><router-link to="/service-details">Service</router-link></li>
              <li><a href="#">Case Studies</a></li>
              <li><router-link to="/news">Blog</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
            </ul>
          </div> -->
        </div>
        <div class="col-lg-3 col-md-6">
          <!-- <div class="footer-navigation">
            <h4 class="title">Support</h4>
            <ul>
              <li><a href="#">Community</a></li>
              <li><a href="#">Resources</a></li>
              <li><a href="#">Faqs</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Careers</a></li>
            </ul>
          </div> -->
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Get In Touch</h4>
            <ul>
              <li>
                <a href="#"
                  ><i class="fal fa-envelope"></i> admin@sparklet.com.mx</a
                >
              </li>
              <li>
                <a href="#"><i class="fal fa-phone"></i> +52 5519534217</a>
              </li>
              <li>
                <a href="#"><i class="fal fa-phone"></i> +52 5548803178</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="
              footer-copyright
              d-flex
              align-items-center
              justify-content-between
              pt-35
            "
          >
            <div class="apps-download-btn">
              <!--<ul>
                <li>
                  <a href="#"><i class="fab fa-apple"></i> Download for iOS</a>
                </li>
                <li>
                  <a class="item-2" href="#"
                    ><i class="fab fa-google-play"></i> Download for Android</a
                  >
                </li>
              </ul>-->
            </div>
            <div class="copyright-text">
              <p>Copyright © 2021 Sparklet. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== sparklet FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style>
</style>