<template>
 <div class="service-details-sidebar mr-50">
                        <div class="service-category-widget">
                            <ul>
                                <li><i class="fal fa-home"></i>Fast Development</li>
                                <li><i class="fal fa-tag"></i>App, UX</li>
                                <li><i class="fal fa-user-friends"></i>20-30 Employees</li>
                                <li><i class="fal fa-bookmark"></i>Sparklet Template</li>
                                <li><i class="fal fa-globe"></i>quomodosoft.com/sparklet</li>
                            </ul>
                        </div>
                        <div class="service-download-widget">
                            <a href="#">
                                <i class="fal fa-download"></i>
                                <span>Download docs</span>
                            </a>
                        </div>
                        <div class="service-download-widget">
                            <a href="#">
                                <i class="fal fa-file-pdf"></i>
                                <span>Characteristics</span>
                            </a>
                        </div>
                    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>