<template>
    <!--====== sparklet HERO PART START ======-->
    
    <section class="sparklet-hero-area sparklet-hero-8-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="sparklet-hero-content sparklet-hero-content-8">                        
                        <h1 class="sparklet-title">We’re a full-range design app</h1>
                        <p>Jolly good excuse my french boot super my good sir cup of char richard about chinwag.</p>
                        <ul>
                            <li><a href="#"><i class="fab fa-google-play"></i>  <span>Google Play</span></a></li>
                            <li><a class="item-2" href="#"><i class="fab fa-apple"></i>  <span>App Store</span></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="sparklet-hero-thumb-6">
                        <div class="thumb text-center wow animated fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <img src="@/assets/images/hero-thumb-9.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-8-shape-1">
            <img src="@/assets/images/shape/3.png" alt="">
        </div>
        <div class="home-8-shape-2">
            <img src="@/assets/images/shape/4.png" alt="">
        </div>
        <div class="home-8-shape-3">
            <img src="@/assets/images/shape/1.png" alt="">
        </div>
        <div class="home-8-shape-4">
            <img src="@/assets/images/shape/2.png" alt="">
        </div>
    </section>
    
    <!--====== sparklet HERO PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>