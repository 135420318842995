<template>
  
    <div class="sparklet-testimonial-about-area pb-45">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="sparklet-section-title text-center">
                        <h3 class="sparklet-title">What clients say about us</h3>
                        <p>The app provides design and digital marketing.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="testimonial-about-slider-active" style="position :relative">
                        <span @click.prevent="showPrev" class="prev slick-arrow" style="display: block;"><i class="fal fa-arrow-left"></i></span>
                        <VueSlickCarousel v-bind="settingsForArticle" ref="articleCarosel">
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                            <div class="testimonial-parent-item">
                                <div class="testimonial-box">
                                <div class="icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="ratings-icon">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <p>Twit some dodgy chav he legged it crikey blatant starkers pukka show off show off pick your nose and blow off morish bum bag boot quaint, Oxford off his nut I bugger up the kyver brilliant bits and bobs haggle buggered.</p>
                            </div>
                            </div>
                        </VueSlickCarousel>
                        <span @click.prevent="showNext" class="next slick-arrow" style="display: block;"><i class="fal fa-arrow-right"></i></span>
                    </div>
                </div>
                <div class="col-lg-4 col-md-8 col-sm-10">
                    <div class="testimonial-box-about-slider-small-active">
                        <VueSlickCarousel v-bind="settingsForPeople" ref="peopleCarosel">
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/testimonial-user-1.png" alt="">
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Bodrum Salvador</h5>
                                    <span>Product Designer</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/testimonial-user-1.png" alt="">
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Bodrum Salvador</h5>
                                    <span>Product Designer</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/testimonial-user-1.png" alt="">
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Bodrum Salvador</h5>
                                    <span>Product Designer</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/testimonial-user-1.png" alt="">
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Bodrum Salvador</h5>
                                    <span>Product Designer</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/testimonial-user-1.png" alt="">
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Bodrum Salvador</h5>
                                    <span>Product Designer</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="thumb">
                                    <img src="@/assets/images/testimonial-user-1.png" alt="">
                                </div>
                                <div class="content text-center">
                                    <h5 class="title">Bodrum Salvador</h5>
                                    <span>Product Designer</span>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
        components:{ VueSlickCarousel},
        data(){ 
            return{
                settingsForArticle: {
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
                settingsForPeople:{
                    centerMode: true,
                    centerPadding: '0px',
                    autoplay: false,
                    arrows:false,
                    dots:false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            }
        },
        methods:{ 
            showNext() {
                this.$refs.articleCarosel.next()
                this.$refs.peopleCarosel.next()
            },
            showPrev(){
                this.$refs.articleCarosel.prev()
                this.$refs.peopleCarosel.prev()
            },
        }


}
</script>

<style>
.testimonial-about-slider-active .testimonial-box{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.slick-slide{
    padding: 0;
}

</style>