<template>
      <!--====== sparklet SERRVICE 2 PART START ======-->
    
    <section class="sparklet-services-2-area pb-100" id="service">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="sparklet-section-title">
                        <h3 class="sparklet-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4">
                    <div class="sparklet-section-title text-right">
                        <a class="main-btn" href="#">View all Features <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-bolt"></i>
                        </div>
                        <h4 class="title">Fast and intuitive</h4>
                        <p>Oxford posh bevvy give us a bell gutted mate spend a penny quaint cockup plastered.</p>
                        <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-home"></i>
                        </div>
                        <h4 class="title">Fast and intuitive</h4>
                        <p>Oxford posh bevvy give us a bell gutted mate spend a penny quaint cockup plastered.</p>
                        <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-link"></i>
                        </div>
                        <h4 class="title">Fast and intuitive</h4>
                        <p>Oxford posh bevvy give us a bell gutted mate spend a penny quaint cockup plastered.</p>
                        <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fas fa-bell"></i>
                        </div>
                        <h4 class="title">Fast and intuitive</h4>
                        <p>Oxford posh bevvy give us a bell gutted mate spend a penny quaint cockup plastered.</p>
                        <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fas fa-cog"></i>
                        </div>
                        <h4 class="title">Fast and intuitive</h4>
                        <p>Oxford posh bevvy give us a bell gutted mate spend a penny quaint cockup plastered.</p>
                        <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fas fa-lock"></i>
                        </div>
                        <h4 class="title">Fast and intuitive</h4>
                        <p>Oxford posh bevvy give us a bell gutted mate spend a penny quaint cockup plastered.</p>
                        <a href="#">Read Mor <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== sparklet SERRVICE 2 PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    }

}
</script>

<style>

</style>