<template>
  <div class="sparklet-dark-mode">
    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />

    <!--====== OFFCANVAS MENU PART ENDS ======-->

    <!--====== PART START ======-->

    <header-home-one
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
      :class="[dark ? 'sparklet-header-area-dark' : '']"
      :dark="dark"
      :enableDark="true"
      @changMode="handlerDarkMode"
    />

    <!--====== PART ENDS ======-->

    <!--====== sparklet HERO PART START ======-->

    <hero-home-one
      :class="[dark ? 'sparklet-hero-area-dark' : '']"
      welcome_message=" "
      slogan="Lleva tu Valet Parking al siguiente nivel y optimiza la manera en la que gestionas tu negocio."
      description="Moderniza tu empresa a través de la tecnología y ofrece el mejor servicio para tus clientes. Elimina tiempos de espera y administra toda tu operación en tiempo real sin necesidad de una app."
      first_button_content="Download for iOS"
      first_button_icon="fab fa-apple"
      second_button_content="Download for Android"
      second_button_icon="fab fa-google-play"
    />

    <!--====== sparklet HERO PART ENDS ======-->

    <!--====== sparklet SERVICES PART START ======-->
    <services-home-one
      :class="[dark ? 'sparklet-service-area-dark' : '']"
      :grid_items="itemsGrid"
      section_heading="Convierte a tu Valet en una experiencia exclusiva."
      more_heading=""
      description="Dile adiós a lo tradicional y anímate a conocer todos los beneficios que Sparklet te puede ofrecer:"
    />
    <!--====== sparklet SERVICES PART ENDS ======-->

    <!--====== sparklet FEATURES PART START ======-->
    <features-home-one :class="[dark ? 'sparklet-features-area-dark' : '']" />

    <!--====== sparklet FEATURES PART ENDS ======-->

    <!--====== sparklet TRAFFIC PART START ======-->

    <traffic-home-one
      :class="[dark ? 'sparklet-traffic-area-dark' : '']"
      :traffic_thumbnail="traffic_thumb"
      small_title=""
      big_title="Despreocúpate y deja que la tecnología trabaje por ti."
      description="Sparklet es un sistema completamente innovador que pretende convertirse en la principal herramienta de trabajo para las compañías operadoras de Valet Parking. Nuestro principal objetivo es aprovechar la tecnología para modernizar la industria del Valet Parking, colaborando con nuestros afiliados con el fin de alcanzar su máximo potencial."
      :features="features"
    />

    <!--====== sparklet TRAFFIC PART ENDS ======-->
    <!--====== sparklet SHOWCASE PART START ======-->

    <!--<show-case-home-three
     :class="[dark ? 'sparklet-showcase-area-dark' : '']"
    />-->

    <!--====== sparklet SHOWCASE PART ENDS ======-->
    <!--====== sparklet TESTIMONIAL PART START ======-->

    <!--<testimonial-home-one :sliderContents="sliderContents" />-->

    <!--====== sparklet TESTIMONIAL PART ENDS ======-->

    <!--====== sparklet TEAM PART START ======-->

    <!--<team-home-one
      :class="[dark ? 'sparklet-team-area-dark' : '']"
      title="Meet our Team Members"
      description="Different layouts and styles for team sections."
      :members="members"
    />-->

    <!--====== sparklet TEAM PART ENDS ======-->

    <!--====== sparklet PRICING PART START ======-->
    <home-about-one
      :class="[dark ? 'sparklet-service-area-dark' : '']"
      :grid_items="aboutItems"
      section_heading="Convierte a tu Valet en una experiencia exclusiva."
      more_heading=""
      description="Dile adiós a lo tradicional y anímate a conocer todos los beneficios que Sparklet te puede ofrecer:"
    />
    <!-- <section class="sparklet-pricing-area sparklet-pricing-area-dark pt-90 pb-90">
        <div class="container">
            <div class="row">
              <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <div class="sparklet-section-title text-center">
                        <h3 class="sparklet-title">Pero eso no es todo</h3>
                        <p> Ademas de todos los beneficios que puede ofrecerte nuestro sistema de gestión, manejo y
organización para tu empresa, tambièn hay otras ventajas que lo hacen único.
Empezando porque, nuestro sistema inteligente Sparklet es extremadamente facil de
utilizar, se le puede aprender a manejar en poco tiempo.<br/>
Ademas de que, los benericios que ofrece, no se limitan unicamente à los àdministradores,
sino que tambien pueden operarlo los empleados y los usuarios para beneficiarse del
mismo.
                        </p>
                    </div>                   
                </div>
            </div>
        </div>
    </section> -->
    <!--<priceing-home-one
      :class="[dark ? 'sparklet-pricing-area-dark' : '']"
      title="Pero eso no es todo"
      description=""
    />-->

    <!--====== sparklet PRICING PART ENDS ======-->

    <!--====== sparklet FAQ PART START ======-->

    <!--<faq-home-one
      :class="[dark ? 'sparklet-faq-area-dark' : '']"
      title="Frequently asked questions"
      description="Different layouts and styles for team sections."
    />-->

    <!--====== sparklet FAQ PART ENDS ======-->

    <!--====== sparklet BLOG PART START ======-->

    <!--<blog-home-one
      :class="[dark ? 'sparklet-blog-area-dark' : '']"
      title="Latest blog posts"
      description="Different layouts and styles for team sections."
      :blogs="blogs"
    />-->

    <!--====== sparklet BLOG PART ENDS ======-->

    <!--====== sparklet PROJECT PART START ======-->

    <project-home-one
      title="¡Haz que todos tus clientes queden encantados con Sparklet!"
      description=""
    />

    <!--====== sparklet PROJECT PART ENDS ======-->

    <!--====== sparklet FOOTER PART START ======-->

    <footer-home-one :class="[dark ? 'sparklet-footer-area-dark' : '']" />

    <!--====== sparklet FOOTER PART ENDS ======-->

    <!--====== sparklet BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== sparklet BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import vision from "../../assets/images/arcticons_bixby-vision.png";
import Historia from "../../assets/images/bx_target-lock.png";
import Mision from "../../assets/images/akar-icons_book.png";
import Filosofia from "../../assets/images/fluent-emoji-high-contrast_brain.png";

import blogOne from "../../assets/images/blog-1.jpg";
import blogTwo from "../../assets/images/blog-2.jpg";
import blogThree from "../../assets/images/blog-3.jpg";
import memberOne from "../../assets/images/team-1.jpg";
import memberTwo from "../../assets/images/team-2.jpg";
import memberThree from "../../assets/images/team-3.jpg";
import memberFour from "../../assets/images/team-4.jpg";
import testmonialAvater from "../../assets/images/testimonial-user.png";
import traffic_thumb from "../../assets/images/traffic-thumb.png";
import icon_one from "../../assets/images/icon/clock.png";
import icon_two from "../../assets/images/icon/credit-card.png";
import icon_three from "../../assets/images/icon/parking.png";
import icon_four from "../../assets/images/icon/unsatisfied.png";
import HeaderHomeOne from "../HomeOne/HeaderHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
import HeroHomeOne from "../HomeOne/HeroHomeOne.vue";
import ServicesHomeOne from "../HomeOne/ServicesHomeOne.vue";
import FeaturesHomeOne from "../HomeOne/FeaturesHomeOne.vue";
import TrafficHomeOne from "../HomeOne/TrafficHomeOne.vue";
import HomeAboutOne from "../HomeOne/HomeAboutOne.vue";
//import TestimonialHomeOne from "../HomeOne/TestimonialHomeOne.vue";
//import ShowCaseHomeThree from "../HomeThree/ShowCaseHomeThree.vue";
//import TeamHomeOne from "../HomeOne/TeamHomeOne.vue";
//import PriceingHomeOne from "../HomeOne/PriceingHomeOne.vue";
//import FaqHomeOne from "../HomeOne/FaqHomeOne.vue";
//import BlogHomeOne from "../HomeOne/BlogHomeOne.vue";
import ProjectHomeOne from "../HomeOne/ProjectHomeOne.vue";
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
export default {
  components: {
    HeaderHomeOne,
    SidebarHomeOne,
    HeroHomeOne,
    ServicesHomeOne,
    FeaturesHomeOne,
    TrafficHomeOne,
    HomeAboutOne,
    //TestimonialHomeOne,
    //ShowCaseHomeThree,
    //TeamHomeOne,
    //PriceingHomeOne,
    //FaqHomeOne,
    //BlogHomeOne,
    ProjectHomeOne,
    FooterHomeOne,
  },
  data() {
    return {
      dark: false,
      traffic_thumb: traffic_thumb,
      testmonialAvater: testmonialAvater,
      sidebar: false,
      showloading: true,
      navs: [
        {
          name: "Inicio",
          path: "/"
        },
        {
          name: "Servicio",
          path: "#service-details"
        },
      ],
      itemsGrid: [
        {
          count: 1,
          icon: icon_one,
          title: "",
          content: "Garantiza la rapidez de tu servicio permitiendo solicitudes de vehículos anticipadas. ",
          customClass: "",
        },
        {
          count: 2,
          icon: icon_three,
          title: "",
          content: "Eficientiza el uso de  tus espacios de estacionamiento.",
          customClass: "item-3",
        },
        {
          count: 3,
          icon: icon_two,
          title: "",
          content: "Elimina los boletos de papel tradicionales y reemplazalos con boletos electrónicos.",
          customClass: "item-2",
        },
        {
          count: 4,
          icon: icon_four,
          title: "",
          content: "Recibe reportes diarios de manera automática.",
          customClass: "item-4",
        },
      ],
      features: [
        {
          title: "",
          bg: "", // create class for style
          description: "Monitorización de entradas y salidas de vehículos en tiempo real",
        },
        {
          title: "",
          bg: "item-2", // create class for style
          description: "Creación de base de datos",
        },
        {
          title: "",
          bg: "item-3", // create class for style
          description: "Monitorización de los empleados de tu empresa",
        },
        {
          title: "",
          bg: "item-4", // create class for style
          description: "Mejor control de los vehículos recibidos y entregados",
        },
      ],
      sliderContents: [
        {
          title: "Jason Response",
          date: "April 14, 2022",
          description:
            "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
          ratings: 5,
          avatar: testmonialAvater,
        },
        {
          title: "Jason Response",
          date: "April 14, 2022",
          description:
            "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
          ratings: 5,
          avatar: testmonialAvater,
        },
        {
          title: "Jason Response",
          date: "April 14, 2022",
          description:
            "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
          ratings: 2,
          avatar: testmonialAvater,
        },
        {
          title: "Jason Response",
          date: "April 14, 2022",
          description:
            "Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.",
          ratings: 3,
          avatar: testmonialAvater,
        },
      ],
      aboutItems: [
        {
          count: 1,
          icon: vision,
          title: "Visión",
          content: "Convertir a Sparklet en el sistema líder en México para gestión de Valet Parking.",
          customClass: "",
        },
        
        {
          count: 3,
          icon: Mision,
          title: "Misión",
          content: "Ayudar a las empresas operadoras de Valet Parking en México a alcanzar su máximo potencial, proporcionándoles una herramienta inteligente adaptada a sus necesidades.",
          customClass: "item-2",
        },
        {
          count: 2,
          icon: Historia,
          title: "Historia",
          content: "Sparklet surge de la necesidad de los usuarios por mejorar la experiencia de servicio de valet Parking, modernizando y adaptando a las necesidades específicas de los usuarios y operadores.",
          customClass: "item-3",
        },
        {
          count: 4,
          icon: Filosofia,
          title: "Filosofía",
          content: "Colaborar y crecer de la mano de nuestros afiliados, fomentando una relación de éxito mutuo.",
          customClass: "item-4",
        },
      ],
      memberOne: memberOne,
      memberTwo: memberTwo,
      memberThree: memberThree,
      memberFour: memberFour,
      members: [
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberTwo,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://www.twitter.com",
              icon: "fab fa-twitter", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberOne,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://www.twitter.com",
              icon: "fab fa-twitter", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberThree,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberFour,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://www.twitter.com",
              icon: "fab fa-twitter", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
      ],
      blogOne: blogOne,
      blogTwo: blogTwo,
      blogThree: blogThree,
      blogs: [
        {
          img: blogOne,
          date: "March 10, 2022",
          category: "Saas & App",
          title: "Introducing New Apps Design for our iOS App",
        },
        {
          img: blogTwo,
          date: "March 10, 2022",
          category: "Saas & App",
          title: "How to bring Dolce to your company",
        },
        {
          img: blogThree,
          date: "March 10, 2022",
          category: "Saas & App",
          title: "17 Effective Ways to Monetize Mobile Apps",
        },
      ],
    };
  },
  created() {
    this.preLoading();
    document.addEventListener("scroll", this.topToBottom);

  },
  mounted() {
    if (this.dark) {
      document.body.classList.add("sparklet-dark");

    } else {
      document.body.classList.remove("sparklet-dark");
    }
  },
  beforeDestroy() {
    document.body.classList.remove("sparklet-dark");

  },

  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
      }, 2000);
    },
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    handlerDarkMode() {
      this.dark = !this.dark;
      //if (this.dark) {
      //    document.body.classList.add("sparklet-dark");
     // } else {
        document.body.classList.remove("sparklet-dark");
     // }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
.sparklet-pricing-area{
  background-color: #fff !important;
}
.sparklet-pricing-area h3{
  color: black !important;
}
.sparklet-pricing-area p{
  color: black !important;
}
</style>