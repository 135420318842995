<template>
      <!--====== sparklet HERO PART START ======-->
    
    <section class="sparklet-hero-area sparklet-hero-6-area sparklet-hero-7-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="sparklet-hero-content sparklet-hero-content-6 sparklet-hero-content-7">
                        <h1 class="sparklet-title">Creative way to Showcase your app </h1>
                        <p>Lost the plot so I said nancy boy I don't want no agro bleeder bum bag easy peasy cheesed off cheers ruddy.</p>
                        <ul>
                            <li><a href="#"><i class="fab fa-apple"></i> <span>Available on the <span>App Store</span></span></a></li>
                            <li><a class="item-2" href="#"><i class="fab fa-google-play"></i> <span>Available on the <span>Google Play</span></span></a></li>
                        </ul>
                        <div class="info">
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="sparklet-hero-thumb-6">
                        <div class="thumb wow animated fadeInUp text-center" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <img src="@/assets/images/hero-thumb-8.png" alt="">
                            <div class="back-image">
                                <img src="@/assets/images/hero-shape-2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== sparklet HERO PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>