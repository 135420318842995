<template>
  <!--====== sparklet FEATURES PART START ======-->
    <section class="sparklet-features-area pt-50 pb-50" id="features">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="sparklet-features-tabs-btn">
                        <div class="nav flex-column nav-pills"  role="tablist" aria-orientation="vertical">
                            <a @click.prevent="selectFeature('setting')" class="nav-link" :class="[selectedTab==='setting'?'active':'']" data-toggle="pill"  role="tab" aria-controls="v-pills-home" aria-selected="true"><i class="fas fa-cog"></i> Boleto electrónico</a>
                            <a @click.prevent="selectFeature('report')" class="nav-link" :class="[selectedTab==='report'?'active':'']"  data-toggle="pill" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i class="fas fa-exclamation-triangle"></i> Estatus</a>
                            <a @click.prevent="selectFeature('notice')" class="nav-link" :class="[selectedTab==='notice'?'active':'']"  data-toggle="pill"  role="tab" aria-controls="v-pills-messages" aria-selected="false"><i class="fas fa-bell"></i> Entrega</a>
                            <a @click.prevent="selectFeature('lock')" class="nav-link" :class="[selectedTab==='lock'?'active':'']" data-toggle="pill"  role="tab" aria-controls="v-pills-settings" aria-selected="false"><i class="fas fa-lock"></i> Seguridad</a>
                        </div>
                    </div>  
                </div>
                <div class="col-lg-9">
                    <div class="tab-content" >
                            <div :class="[selectedTab==='setting'?'active show':'']"  class="tab-pane fade "  role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img src="../../assets/images/features-thumb-1-3.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-content wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <span></span>
                                            <h3 class="title">Cómo funciona Sparklet?</h3>
                                            <p>Sparklet está conformado por 3 perfiles distintos, el administrador, el conductor y el cliente</p>
                                            <b>Administrador</b>
<ul>
    <li>Información general de la operación en un solo lugar y en tiempo real. Posibilidad de agregar y gestionar Sub-Administradores para distintos puntos de operación.</li>
    <li>Gestión de empleados, reportes y reseñas.</li>
    <li>Reportes de ingresos y salidas de vehículos en tiempo real.</li>
    <li>Reportes de pagos en tiempo real.</li>
    <li>Base de datos generada automáticamente disponible para futuras consultas.</li>
 </ul>
<b>Conductor</b>
<ul>
    <li>Recolectar y entregar coches de forma electrónica.</li>
    <li>Perfiles y reportes individuales para llevar un mayor control sobre el trabajo realizado. </li>
    <li>Información completa de los vehículos para ubicarlos con mayor facilidad. </li>
    <li>Notificaciones sobre pedidos realizados con anticipación.</li>
    <li>Cálculo de tarifas automático.</li>
</ul>
<b>Cliente</b>
<ul>
    <li>Boletos electrónicos mediante un mensaje de texto.</li>
    <li>Posibilidad de ordenar el vehículo con anticipación eliminando tiempos de espera.</li>
    <li>Posibilidad de pago con tarjeta de crédito o efectivo.</li>
    <li>Acceso a la información de sus vehículos en todo momento.</li>
    <li>Mayor seguridad a la entrega del vehículo por medio de un código de seguridad.</li>
</ul>

                                            <!--<router-link class="main-btn" to="/about-us">Más Informacion</router-link>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="[selectedTab==='report'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img src="../../assets/images/features-thumb-1-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <span></span>
                                            <h3 class="title">Cómo funciona Sparklet?</h3>
                                            <p>Sparklet está conformado por 3 perfiles distintos, el administrador, el conductor y el cliente</p>
                                            <b>Administrador</b>
<ul>
    <li>Información general de la operación en un solo lugar y en tiempo real. Posibilidad de agregar y gestionar Sub-Administradores para distintos puntos de operación.</li>
    <li>Gestión de empleados, reportes y reseñas.</li>
    <li>Reportes de ingresos y salidas de vehículos en tiempo real.</li>
    <li>Reportes de pagos en tiempo real.</li>
    <li>Base de datos generada automáticamente disponible para futuras consultas.</li>
 </ul>
<b>Conductor</b>
<ul>
    <li>Recolectar y entregar coches de forma electrónica.</li>
    <li>Perfiles y reportes individuales para llevar un mayor control sobre el trabajo realizado. </li>
    <li>Información completa de los vehículos para ubicarlos con mayor facilidad. </li>
    <li>Notificaciones sobre pedidos realizados con anticipación.</li>
    <li>Cálculo de tarifas automático.</li>
</ul>
<b>Cliente</b>
<ul>
    <li>Boletos electrónicos mediante un mensaje de texto.</li>
    <li>Posibilidad de ordenar el vehículo con anticipación eliminando tiempos de espera.</li>
    <li>Posibilidad de pago con tarjeta de crédito o efectivo.</li>
    <li>Acceso a la información de sus vehículos en todo momento.</li>
    <li>Mayor seguridad a la entrega del vehículo por medio de un código de seguridad.</li>
</ul>

                                            <!--<a class="main-btn" href="#">Más Informacion</a>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="[selectedTab==='notice'?'active show':'']"  class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img src="../../assets/images/features-thumb-1.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <span></span>
                                            <h3 class="title">Cómo funciona Sparklet?</h3>
                                            <p>Sparklet está conformado por 3 perfiles distintos, el administrador, el conductor y el cliente</p>
                                            <b>Administrador</b>
<ul>
    <li>Información general de la operación en un solo lugar y en tiempo real. Posibilidad de agregar y gestionar Sub-Administradores para distintos puntos de operación.</li>
    <li>Gestión de empleados, reportes y reseñas.</li>
    <li>Reportes de ingresos y salidas de vehículos en tiempo real.</li>
    <li>Reportes de pagos en tiempo real.</li>
    <li>Base de datos generada automáticamente disponible para futuras consultas.</li>
 </ul>
<b>Conductor</b>
<ul>
    <li>Recolectar y entregar coches de forma electrónica.</li>
    <li>Perfiles y reportes individuales para llevar un mayor control sobre el trabajo realizado. </li>
    <li>Información completa de los vehículos para ubicarlos con mayor facilidad. </li>
    <li>Notificaciones sobre pedidos realizados con anticipación.</li>
    <li>Cálculo de tarifas automático.</li>
</ul>
<b>Cliente</b>
<ul>
    <li>Boletos electrónicos mediante un mensaje de texto.</li>
    <li>Posibilidad de ordenar el vehículo con anticipación eliminando tiempos de espera.</li>
    <li>Posibilidad de pago con tarjeta de crédito o efectivo.</li>
    <li>Acceso a la información de sus vehículos en todo momento.</li>
    <li>Mayor seguridad a la entrega del vehículo por medio de un código de seguridad.</li>
</ul>

                                            <!--<a class="main-btn" href="#">Más Informacion</a>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="[selectedTab==='lock'?'active show':'']" class="tab-pane fade"  role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <img src="../../assets/images/features-thumb-1-4.png" alt=""/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="sparklet-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <span></span>
                                            <h3 class="title">Cómo funciona Sparklet?</h3>
                                            <p>Sparklet está conformado por 3 perfiles distintos, el administrador, el conductor y el cliente</p>
                                            <b>Administrador</b>
<ul>
    <li>Información general de la operación en un solo lugar y en tiempo real. Posibilidad de agregar y gestionar Sub-Administradores para distintos puntos de operación.</li>
    <li>Gestión de empleados, reportes y reseñas.</li>
    <li>Reportes de ingresos y salidas de vehículos en tiempo real.</li>
    <li>Reportes de pagos en tiempo real.</li>
    <li>Base de datos generada automáticamente disponible para futuras consultas.</li>
 </ul>
<b>Conductor</b>
<ul>
    <li>Recolectar y entregar coches de forma electrónica.</li>
    <li>Perfiles y reportes individuales para llevar un mayor control sobre el trabajo realizado. </li>
    <li>Información completa de los vehículos para ubicarlos con mayor facilidad. </li>
    <li>Notificaciones sobre pedidos realizados con anticipación.</li>
    <li>Cálculo de tarifas automático.</li>
</ul>
<b>Cliente</b>
<ul>
    <li>Boletos electrónicos mediante un mensaje de texto.</li>
    <li>Posibilidad de ordenar el vehículo con anticipación eliminando tiempos de espera.</li>
    <li>Posibilidad de pago con tarjeta de crédito o efectivo.</li>
    <li>Acceso a la información de sus vehículos en todo momento.</li>
    <li>Mayor seguridad a la entrega del vehículo por medio de un código de seguridad.</li>
</ul>

                                            <!--<a class="main-btn" href="#">Más Informacion</a>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="features-shape-1">
            <img src="../../assets/images/shape/shape-6.png" alt="">
        </div>
        <div class="features-shape-2">
            <img src="../../assets/images/shape/shape-7.png" alt="">
        </div>
        <div class="features-shape-3">
            <img src="../../assets/images/shape/shape-8.png" alt="">
        </div>
    </section>
    
    <!--====== sparklet FEATURES PART ENDS ======-->
</template>

<script>
export default {
    data(){
        return{
            selectedTab:'setting',
            switchPlan:true,
        }
    },
    methods:{
         selectFeature(name){
            this.selectedTab=name
        },
    }
}
</script>

<style>
section#features ul li {
    list-style: disc;
    margin-left: 20px;
}

</style>