<template>
  
      <!--====== sparklet HERO PART START ======-->
    
    <section class="sparklet-hero-area sparklet-hero-4-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="sparklet-hero-content sparklet-hero-content-4">
                        <span>30 Days Free Trial</span>
                        <h1 class="sparklet-title">Get it done with sparklet.io</h1>
                        <p>Tosser lemon squeezy chancer hanky panky arse plastered show off show off pick your nose and blow knackered bugger porkies.</p>
                        <a class="main-btn" href="#">Let’s Talk</a>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="sparklet-hero-thumb sparklet-hero-thumb-4">
                        <img src="@/assets/images/hero-thumb-5.png" alt="">
                        <div class="hero-dot">
                            <img src="@/assets/images/hero-dot.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hero-shape-1">
            <img src="@/assets/images/shape/shape-10.png" alt="">
        </div>
    </section>
    
    <!--====== sparklet HERO PART ENDS ======-->

</template>

<script>
export default {

}
</script>

<style>

</style>