<template>
    <!--====== sparklet PROJECT PART START ======-->

    <section class="sparklet-project-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div
                        class="sparklet-project-box wow animated slideInUp"
                        data-wow-duration="1000ms"
                        data-wow-delay="0ms"
                    >
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="sparklet-project-content">
                                    <h3 class="title">{{ title }}</h3>
                                    <p>{{ description }}</p>
                                    
                                        <div class="input-box mt-30">
                                            <input
                                                :value="text"
                                                @input="
                                                    (event) =>
                                                        (text =
                                                            event.target.value)
                                                "
                                                type="text"
                                                placeholder="Correo electronico"
                                            />
                                            <button type="button" @click="subscribe()">Subscribir</button>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                        <!--<div class="sparklet-project-thumb">
                            <img
                                src="../../assets/images/a3.jpg"
                                alt=""
                                style=" max-height: 400px;"
                            />
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--====== sparklet PROJECT PART ENDS ======-->
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
    },
    data() {
        return {
            text: "",
        };
    },
    methods: {
        subscribe() {
            console.log(this.text);
            this.axios
                .post("api/mail_list", { email: this.text, name: "NULL" })
                .then((response) => {
                    console.log(response);
                }).catch((e)=>{
                    console.log(e);
                });
        },
    },
};
</script>

<style></style>
