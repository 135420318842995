<template>
  <section class="sparklet-services-2-area pt-90 pb-55" id="service">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-6 col-md-8">
                    <div class="sparklet-section-title">
                        <h3 class="sparklet-title">We’re driven by our values</h3>
                        <p>The app provides design and digital marketing. </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 sparklet-single-service-about mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-tv"></i>
                        </div>
                        <h4 class="title">Carefully designed</h4>
                        <p>He lost his bottle loo don't get shirty with me ruddy.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 sparklet-single-service-about item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-code"></i>
                        </div>
                        <h4 class="title">Clean Modern Code</h4>
                        <p>He lost his bottle loo don't get shirty with me ruddy.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 sparklet-single-service-about item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-user-friends"></i>
                        </div>
                        <h4 class="title">User Interactive</h4>
                        <p>He lost his bottle loo don't get shirty with me ruddy.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 sparklet-single-service-about item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <i class="fal fa-mobile"></i>
                        </div>
                        <h4 class="title">Choose a App</h4>
                        <p>He lost his bottle loo don't get shirty with me ruddy.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 sparklet-single-service-about item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <i class="fal fa-retweet"></i>
                        </div>
                        <h4 class="title">Seamless Sync</h4>
                        <p>He lost his bottle loo don't get shirty with me ruddy.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sparklet-single-service-2 sparklet-single-service-about item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <i class="fal fa-bell"></i>
                        </div>
                        <h4 class="title">Notifications Settings</h4>
                        <p>He lost his bottle loo don't get shirty with me ruddy.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>