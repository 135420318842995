<template>
  <!--====== sparklet PROJECT PART START ======-->

  <section class="sparklet-project-area sparklet-project-area-rtl pb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="sparklet-project-box wow animated slideInUp"
            data-wow-duration="1000ms"
            data-wow-delay="0ms"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="sparklet-project-content">
                  <h3 class="title">{{ title }}</h3>
                  <p>{{ description }}</p>
                  <form action="#">
                    <div class="input-box mt-30">
                      <input type="text" placeholder="Your email" />
                      <button>الإشتراك</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="sparklet-project-thumb">
              <img src="@/assets/images/a3.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== sparklet PROJECT PART ENDS ======-->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>

<style>
</style>